import React, { useState, useEffect } from "react";

import $ from "jquery";
import { Container, Row, Col, Button, Form, Modal, Jumbotron, Alert } from "react-bootstrap";
import "./App.css";

const templates = require.context('../public/static/json/templates', true);
const uiImages = require.context('../public/static/assets/images/ui', true);
const allTemplates = templates('./templates.json');

const uiCheck = uiImages('./check-mark.png');

var template = false;
var screencnt = 0;

// This is where loaded json is converted into html
function recurse(data, nLevel = 0) {
  var htmlRetStr;
  htmlRetStr = "<ul id='screens"+nLevel+"' class='recurseObj screens"+nLevel+"' >";

  for (var key in data) {
    let arrayTest = Array.isArray(data);
    if (typeof data[key] == "object" && data[key] != null) {
      if (!arrayTest) {
        htmlRetStr +=
          "<li id='screen"+nLevel+screencnt+"' class='li-object' data-level='" +
          nLevel +
          "'><div class='title singleKey object bold editable' contenteditable='true'>" +
          key +
          "</div>";
        htmlRetStr += recurse(data[key], nLevel + 1);
        htmlRetStr += "</li>";
      } else {
        htmlRetStr += "<li class='li-object'  data-level='" + nLevel + "'>";
        htmlRetStr += recurse(data[key], nLevel + 1);
        htmlRetStr += "</li>";
      }
    } else {
      htmlRetStr +=
        "<li class='li-single' data-level='" +
        nLevel +
        "'><div class='title singleKey bold editable' contenteditable='true'>" +
        key +
        "</div>&nbsp;<div class='singleValue editable' contenteditable='true'>" +
        data[key] +
        "</div></li>";
    }
    screencnt++;
  }

  htmlRetStr += "</ul >";

  return htmlRetStr;
}

// html is converted to json and saved to file system
function exportJson(e, type) {
  function parseRecurseObjs($ulRecurseObj, nLevel = 0) {
    var json = {};
    function fParseSubs(i, node) {
      let sVarName;
      let $node = $(node);
      let $spanTitle = $($node.find(".singleKey")[0]);
      sVarName = $spanTitle.text();
      if ($node.hasClass("li-object")) {
        var parsedObject = parseRecurseObjs($node, nLevel + 1);
        json[sVarName] = parsedObject;
      } else if ($node.hasClass("li-single")) {
        json[sVarName] = $($node.find(".singleValue")).text();
      }
    }
    let $nodeSubs = $ulRecurseObj.find("[data-level=" + nLevel + "]");
    $nodeSubs.each(fParseSubs);
    return json;
  }
  function fFineTune(json) {
    let v;
    for (v in json) {
      let o = json[v];
      if (typeof o !== "string") {
        if (o[v]) {
          o = o[v];
        }
        json[v] = fFineTune(o);
      }
    }
    return json;
  }

  let finalJson = parseRecurseObjs($($("#output").find(".recurseObj")[0]));
  finalJson = fFineTune(finalJson);

  if (type !== "save") {
    exportToJsonFile(finalJson);
  }

  localStorage.setItem("local-json", JSON.stringify(finalJson));

}


// uploaded json is placed into html structure and saved to local storage
function placeJson(e) {
  var files = document.getElementById("inputFile").files;
  if (files.length <= 0) {
    alert("nothing uploaded...");
    return false;
  }
  var fr = new FileReader();
  fr.onload = function(e) {
    var result = JSON.parse(e.target.result);
    viewJson(result, "replace");

    localStorage.setItem("local-json", JSON.stringify(result));
    showAlert('success','JSON Saved!');

  };
  fr.readAsText(files.item(0));
}

function showAlert(type, message) {
    let alert = document.getElementById('alertModal');
    let icon = document.createElement('img');
    let span = document.createElement('span');
        icon.src = uiCheck;
        $(span).html(message);
        $(icon).addClass("img-fluid");
        $(alert).addClass("alert-"+type+" ");
        $(alert).html(icon);
        $(alert).append(span);
        setTimeout(function() {
            $(alert).addClass("in")
        }, 100);
        setTimeout(function() {
            $(alert).removeClass("in")
        }, 2000);
}

// delete element
function deleteElement(e) {
  let thisEl = e.target;
  let screen = $(thisEl).parent().parent();
  let parent = $(screen).parent();
  screen.addClass("delanimate");

  setTimeout(function() {
    screen.remove();
  }, 100);
  
  setTimeout(function() {
    if(parent.find('li').length == 0) {
        parent.parent().remove();
    }
    exportJson(null, "save");
    getScreens();
  }, 200);

  e.stopPropagation();
}

// create element
function createBlock(e) {
  if (!e) {
    return;
  }
  let thisEl = e.target;
  let placement;
  if (null !== e.target.attributes.getNamedItem("data-selector")) {
    placement = e.target.attributes.getNamedItem("data-selector").value;
  }
  let thisElParent = $(thisEl)
    .parent()
    .parent();
  let level = thisElParent.attr("data-level") || 1;
  let elId = "content" + Math.floor(Math.random() * 10000 + 1);
  let clone = $("#copyObj")
    .clone()
    .attr("id", elId)
    .attr("data-level", level)
    .removeClass("hidden");
  clone
    .children("ul.recurseObj")
    .children("li.li-single")
    .attr("data-level", parseInt(level) + 1);

  if (placement) {
    let output = $("#output").children("ul.recurseObj");
    output = output.find("ul.recurseObj")[0];
    $(output).append(clone);
  } else {
    thisElParent.after(clone);
  }

  clone.find(".addScreen").on("click", function(e) {
    createBlock(e);
    e.stopPropagation();
  });

  clone.find(".delScreen").on("click", function(e) {
    deleteElement(e);
    e.stopPropagation();
  });

  clone.find(".cloneScreen").on("click", function(e) {
    cloneBlock(e);
    e.stopPropagation();
  });

  $(".singleKey").hover(
    function() {
      $("li").removeClass("show");
      $(this).parent('li').addClass("show");
    },
    function() {
      //   $( this ).delay( 400 ).removeClass( "show" ) ;
    }
  );

  setTimeout(function() {
    exportJson(null, "save");
    getScreens();
  }, 500);
  e.stopPropagation();
}

// clone element
function cloneBlock(e) {
  let thisEl = e.target;
  let thisElParent = $(thisEl)
    .parent()
    .parent();
    let objCnt = thisElParent.parent().children().length + 1;
  let typeObj = thisElParent.hasClass("li-object");
  let elId = "content" + Math.floor(Math.random() * 10000 + 1);
  let clone = thisElParent.clone().attr("id", elId);
  let title = clone.find('.singleKey').first().text();
  let screenTest = title.includes("screen");
  let cloneTitle = "";
  if(screenTest) {
    cloneTitle = 'screen'+objCnt
  } else {
    cloneTitle = 'content'+objCnt 
  }
  clone.find('.singleKey').first().text(cloneTitle);
  thisElParent.after($(clone));

  clone.find(".addScreen").on("click", function(e) {
    createBlock(e);
    e.stopPropagation();
  });

  clone.find(".delScreen").on("click", function(e) {
    deleteElement(e);
    e.stopPropagation();
  });

  clone.find(".cloneScreen").on("click", function(e) {
    cloneBlock(e);
    e.stopPropagation();
  });

  $(".singleKey").hover(
    function() {
      $("li").removeClass("show");
      $(this).parent('li').addClass("show");
    },
    function() {
      //   $( this ).delay( 400 ).removeClass( "show" ) ;
    }
  );

  if (typeObj) {
    let newId = document.getElementById(elId);
    newId.scrollIntoView({ block: "start", behavior: "smooth" });
  }

  setTimeout(function() {
    exportJson(null, "save");
    getScreens();
  }, 500);

  return false;
}

// put a ui on top of the html
function viewJson(jsonobj, placement) {
    let jsonStr = recurse(jsonobj);
    let freshStart = true;
  let output = document.getElementById("output");
  let outputSub = $(output).children("ul.recurseObj");
  if( outputSub.find("ul.recurseObj")[0] !== undefined) {
        outputSub = outputSub.find("ul.recurseObj")[0];
        freshStart = false;
  }
  if (placement === "append" && !freshStart) {
    if(template) jsonStr = templatePrep(jsonStr); 
    $(outputSub).append( jsonStr );
    window.scrollTo(0,document.body.scrollHeight);
  } else {
    output.innerHTML = jsonStr;
  }
  setTimeout(function() {
    createUIButtons("li");
    getScreens();
    template = false;
  }, 500);
}

function templatePrep(markup) {
    let finalMarkup = $(markup).find('#screens1').find('li').first();
    return finalMarkup;
}

// create ui buttons
function createUIButtons(selector) {

  if( $('.uiNav').length > 0 ) {
    $('.uiNav').remove();
  }
  let uiNav = document.createElement("div");
  uiNav.className = "uiNav";

  let delScreen = document.createElement("button");
  delScreen.className = "delScreen btn btn-danger btn-sm mini-nav";
  delScreen.innerHTML = "x";

  let addScreen = document.createElement("button");
  addScreen.className = "addScreen btn btn-primary btn-sm mini-nav";
  addScreen.innerHTML = "+";

  let cloneScreen = document.createElement("button");
  cloneScreen.className = "cloneScreen btn btn-info btn-sm mini-nav";
  cloneScreen.innerHTML = "=";

  $(uiNav)
    .append(delScreen)
    .append(addScreen)
    .append(cloneScreen);

  $(selector).prepend($(uiNav));

  $(".addScreen").on("click", function(e) {
    createBlock(e);
    e.stopPropagation();
  });
  $(".delScreen").on("click", function(e) {
    deleteElement(e);
    e.stopPropagation();
  });
  $(".cloneScreen").on("click", function(e) {
    cloneBlock(e);
    e.stopPropagation();
  });
  $(".singleKey").hover(
    function() {
      $("li").removeClass("show");
      $(this).parent('li').addClass("show");
    },
    function() {
      //   $( this ).delay( 400 ).removeClass( "show" ) ;
    }
  );
}

// file prep for exporting json
function exportToJsonFile(jsonData) {
  let dataStr = JSON.stringify(jsonData);
  let dataUri =
    "data:application/json;charset=utf-8," + encodeURIComponent(dataStr);
  let exportFileDefaultName = "data.json";
  let linkElement = document.createElement("a");

  linkElement.setAttribute("href", dataUri);
  linkElement.setAttribute("download", exportFileDefaultName);
  linkElement.click();
}

function showdReplaceFileName(e) {
  var input = e.srcElement;
  var fileName = input.files[0].name;
  let uploadJson = document.getElementById("inputFileLabel");
  uploadJson.innerHTML = fileName;
}

function clearJson(e) {
  let clearIt = document.querySelectorAll('[data-level="1"]');
  $(clearIt).remove();
  setTimeout(function() {
    getScreens();
  }, 500);
}

function getScreens() {
    let lnCnt = document.querySelectorAll('[data-level="1"]').length;
    let div = document.createElement("div");
    div.className = "d-flex flex-column text-center position-fixed";
    for (var i=0;i<lnCnt;i++) {
        let span = document.createElement("span");
        let screenId = document.querySelectorAll('[data-level="1"]')[i];
        $(span).attr('data-id', $(screenId).attr('id'));
        span.addEventListener("click", function(){ 
            screenId.scrollIntoView({ block: "start", behavior: "smooth" });
        });
        span.append(i);
        div.append(span);
    }
    $('#mini-nav').html(div);

    $(window).scroll(function() {
        let elements = $("[data-level='1']");
        $(elements).each( function(i, element){
            var top_of_element = $(element).offset().top;
            var bottom_of_element = $(element).offset().top + $(element).outerHeight();
            var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
            var top_of_screen = $(window).scrollTop();
            let thisId = $(element).attr('id');
            if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){
                $("[data-id='"+thisId+"']").addClass('selected');
            } else {
                $("[data-id='"+thisId+"']").removeClass('selected');
            }
        });
    });

}

function getTemplates() {
    var imgUrl = "../static/assets/images/";
    var row = document.createElement("div");
    row.className = "row thumbs";

    $(allTemplates.templates).each( function(i, value){
        let col = document.createElement("div");
            col.className = "templateThumb col col-md-3 py-2";
        let thumb = document.createElement("img");
        let content = document.createElement("label");
            content.append(value.title);
            thumb.className = "thumb img-fluid";
            thumb.setAttribute("data-structure", JSON.stringify(value.structure));
            thumb.title = value.tags;
            thumb.src = imgUrl+value.img;
            $(thumb).on("click", function() {
                $('.thumb').removeClass('selected');
                $(this).addClass('selected');
            })
            col.append(thumb);
            col.append(content);
            row.append(col);
    })

    $('#allTemplates').append(row);

    $( "#filter-courses" ).keyup(function() {
        filterCoursesSearch();
    });

}

function filterCoursesSearch() {
    // Declare variables
    var input, filter, ul, li, a, i, txtValue;
    input = document.getElementById('filter-courses');
    filter = input.value.toUpperCase() || "";
    ul = document.getElementById("allTemplates");
    li = ul.getElementsByClassName('thumb');
  
    // Loop through all list items, and hide those who don't match the search query
    for (i = 0; i < li.length; i++) {
        txtValue = li[i].getAttribute("title");
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        li[i].parentElement.style.display = "";
      } else {
        li[i].parentElement.style.display = "none";
      }
    }
  }

  function createScreen() {
    template = true;
    let json = allTemplates.templates[0].structure;
    setTimeout(function() {
        viewJson(json, "append");
        getScreens();
        window.scrollTo(0,document.body.scrollHeight);
    }, 100);
  }

function App() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShowTemplates = () => {
      template = true;
      setShow(true);
      setTimeout(function() {
        getTemplates();
      }, 500);
      $(document).on('keypress',function(e) {
        var keycode = (e.keyCode ? e.keyCode : e.which);
        if(keycode == '13'){
            addToCourse();
        }
      });
  }
  const handleShow = e => {
    exportJson(e, "save");
    showAlert('success','JSON Saved!');
    e.stopPropagation();
  };
  const addToCourse = e => {
    let json = $('.thumbs').find('.selected').attr('data-structure');
    setTimeout(function() {
        viewJson(JSON.parse(json), "append");
        handleClose();
        getScreens();
      }, 500);
  }

  useEffect(() => {
    var localJson = JSON.parse(localStorage.getItem("local-json"));
    let createJson = document.getElementById("createJson");
    createJson.addEventListener("click", createScreen);
    let clearIt = document.getElementById("clearJson");
    clearIt.addEventListener("click", clearJson);
    let placeIt = document.getElementById("placeJson");
    placeIt.addEventListener("click", placeJson);

    if (!localJson) {
      let elId = "content" + Math.floor(Math.random() * 10000 + 1);
      let clone = $("#copyObj")
        .clone()
        .attr("id", elId)
        .attr("data-level", 0)
        .removeClass("hidden");
      let output = $("#output").children("ul.recurseObj");
      output = output.find("ul.recurseObj")[0];
      $(output).append(clone);
    } else {
      viewJson(localJson, "replace");
    }

    let inputFile = document.getElementById("inputFile");
    inputFile.addEventListener("change", showdReplaceFileName);

    getScreens();

    

    $(document).on('keypress',function(e) {
        var keycode = (e.keyCode ? e.keyCode : e.which);
        // Press T to open templates
        console.log(e.altKey);
        console.log(keycode);
        if ( e.altKey && ( keycode === 8224 ) ) {
            handleShowTemplates();
        }
        // Press S to create a screen
        if ( e.altKey && ( keycode === 223 ) ) {
            createScreen();
        }
    });

  }, []);

  return (
    <>
      <li id="copyObj" class="li-object hidden" data-level="">
        <div class="title singleKey bold editable" contenteditable="true">
          content
        </div>
        <ul class="recurseObj">
          <li class="li-single" data-level="">
            <div class="title singleKey bold editable" contenteditable="true">
              content1
            </div>
            <div class="singleValue editable" contenteditable="true">
              Description
            </div>
          </li>
        </ul>
      </li>
      <Container fluid>
        <Row>
          <Col className="sidebar position-relative p-5" md={3}>
            <div className="sidebar-sticky">
              <h1>Course Content Creator</h1>
              <Form>
                <Row>
                  <Col>
                      <hr></hr>
                      <Row>
                        <Col className="my-2">
                          <strong>New File</strong>
                          <div id="uploadJson" class="input-group">
                            <div class="custom-file">
                              <input
                                id="inputFile"
                                type="file"
                                name="file"
                                class="custom-file-input"
                              />
                              <label
                                id="inputFileLabel"
                                class="custom-file-label"
                                for="inputFile"
                              >
                                Upload
                              </label>
                            </div>
                            <div class="input-group-append">
                              <button
                                id="placeJson"
                                class="btn btn-outline-primary"
                                type="button"
                              >
                                +
                              </button>
                            </div>
                          </div>
                          <hr></hr>
                          <Row className="my-2">
                                <Col>
                                    <Button
                                        id="viewTemplates"
                                        className="my-2 btn-block btn-lg"
                                        onClick={handleShowTemplates}
                                        variant="primary"
                                        data-toggle="modal"
                                        data-target="#loadedTemplates"
                                    >
                                        + Templates <small>[alt/option + t]</small>
                                    </Button>
                                    <Button
                                        id="createJson"
                                        className="my-2 btn-block btn-lg"
                                        variant="primary"
                                    >
                                        + Block <small>[alt/option + s]</small>
                                    </Button>
                                    <Button
                                        id="clearJson"
                                        className="mb-2 mt-4 btn-block btn-sm"
                                        variant="danger"
                                    >
                                        X Clear
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                      </Row>
                      <hr></hr>
                      <Row>
                          <Col>
                          <Form.Group controlId="formLanguage">
                            <Button
                                id="saveJson"
                                onClick={handleShow}
                                className="btn-block btn-lg mt-3"
                                variant="success"
                                data-id="save"
                                data-toggle="modal"
                                data-target="#savedModal"
                            >
                                Save JSON
                            </Button>
                            <Button
                                id="exportJson"
                                onClick={exportJson}
                                className="btn-block btn-lg my-2"
                                variant="secondary"
                                data-id="export"
                            >
                                Export JSON
                            </Button>
                            </Form.Group>
                        </Col>
                      </Row>
                    
                  </Col>
                </Row>
              </Form>
            </div>
          </Col>
          <Col md={9}>
            <div class="fader alert show" role="alert" id="alertModal"></div>
            <article id="output" className="m-4">
                <Jumbotron>
                    <p className="mt-4">
                        <h3>To get started, try these options in the left column:</h3>
                        <ul>
                            <li>• Upload a course json, "Upload", then press [ + ]</li>
                            <li>• Select from a list of templates, "+ Template"</li>
                            <li>• Create your own by clicking "+ Screen"</li>
                            <li>Note: <strong>KEYS MUST HAVE UNIQUE NAMES</strong>, otherwise they will not be saved.</li>
                        </ul>
                    </p>
                </Jumbotron>
              {viewJson}
            </article>
          </Col>
        </Row>
      </Container>

      <div id="mini-nav" className="miniNav"></div>

      {/* <Modal id="savedModal" show={show} onHide={handleClose}>
        <Modal.Body>JSON Saved!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal> */}

      <Modal id="loadedTemplates" size={'xl'} scrollable keyboard show={show} onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title>
                <div className="d-flex flex-row justify-content-between">
                    <span>Templates</span>
                    <form class="navbar-search">
                        <div class="input-group">
                            <input id="filter-courses" type="text" class="form-control bg-white small" placeholder="Search for..." aria-label="Search" aria-describedby="basic-addon2" />
                        </div>
                    </form>
                </div>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body id="allTemplates"></Modal.Body>
        <Modal.Footer>
        <Button variant="primary" onClick={addToCourse}>
            Add to Course
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default App;
